import React, { Fragment } from 'react'
import "./home.css"

const Home = () => {
  return (
    <Fragment>
            <div className='homecontainer'>
                <h1>Discover Your Perfect Living Space.</h1>
            </div>
    </Fragment>
    
  )
}

export default Home